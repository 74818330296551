<template>
  <div id="app" role="main">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  mounted: function () {
    if (!this.$store.getters.loaded) {
      this.$store.dispatch("getProperties");
      this.$store.dispatch("getReviews");
    } else if (this.$store.getters.checkExpiryDate) {
      this.$store.dispatch("getProperties");
      this.$store.dispatch("getReviews");
      this.$store.dispatch("updateCurrentTime");
    }
  },
}

import 'vueperslides/dist/vueperslides.css';
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Karla:400,700,700i|Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
html, body{
  font-family: $primaryFont;
  margin:0;
  padding:0;
  min-height: 100vh;
}

a{
  &:hover{
    opacity: 0.85;
  }
}

*{
  box-sizing: border-box;
}

p{
  font-size: 1rem;
  margin: 0.25rem 0;
  line-height: 1.75rem;

}

button:focus{
  outline: none;
}

.vue-simple-spinner-text{
  letter-spacing: 3px;
  font-size: 1rem !important;
  color: #000 !important;
}

h1{
  font-size: 40px;
}

.btn{
  color:#fff;
  background: #000;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 2.5rem;
  letter-spacing: 2px;
  font-weight: 300;
  margin-bottom: 0rem;
}
</style>