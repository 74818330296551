<template>
    <ul>
        <li v-for="item in menuItems" :key="item.label">
            <router-link :to="item.link">
                {{ item.label }}
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name:"FooterMenu",
    data(){
        return{
            menuItems: [
                {
                    label: "Home",
                    link:"/"
                },
                {
                    label: "About",
                    link:"/about"
                },
                {
                    label: "Properties",
                    link:"/properties"
                },
                {
                    label: "Contact",
                    link:"/contact"
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
ul{
    display: flex;
    justify-content: center;
    list-style-type: none;
    flex-wrap: wrap;
    text-transform: uppercase;
    margin: 0;
    padding: 0.5rem 0;
    li {
        margin: 0 2rem;
        font-weight: 500;
        letter-spacing: 1.5px;
        a {
            text-decoration: none;
            font-size: 0.75rem;
            font-weight: bold;
            color: #fff;
            &:hover{
                opacity: 0.6;
            }
        }
    }
}

@media (max-width: $mobile) {
    ul{
        li{
            margin: 0 0.5rem;
            letter-spacing: 1px;
            font-size: 0.9rem;
        }
    }
}
</style>