<template>
    <div id="footerCredits">
        <p v-html="copyright"></p>
        <p style="display:inline">
            {{credits}}
        </p>
        <br>
        <a href="https://www.austinrobbins.com" target="_blank">Austin Robbins Agency</a>
    </div>
</template>

<script>
export default {
    name: "FooterCredits",
    data(){
        var d = new Date();
        return{
            copyright: `Copyright &copy ${d.getFullYear()} Bob Safranski. @properties, the @properties' logo, and other various trademarks, logos, designs, and slogans herein are the registered and unregistered trademarks of At World Properties, LLC d/b/a @properties.`,
            credits: "Luxury Real Estate Websites by",
        }
    },
}
</script>

<style lang="scss" scoped>
#footerCredits{
    padding: 1.5rem 0;
    padding-bottom: 40px;
    text-align: center;
    p {
        margin: 0 auto;
        padding: 0.25rem 0;
        color: #fff;
        letter-spacing: 1px;
        font-size: 0.925rem;
        max-width: 80%;
    }
    a{
        display: block;
        padding: 0.25rem 0;
        margin-left: 5px;
        color: #fff;
    }
    a:hover{
        opacity: 0.7;
    }
}
</style>