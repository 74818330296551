<template>
    <div class="footer-content">
        <router-link to="/" class="property-tile">  
            <img class="logo" src="/images/client-logo.png" alt="Client Logo">
        </router-link>
        <hr>
        <p><a class="contact" :href="'tel:'+ telephone">{{telephone}}</a></p>
        <p><a class="contact" :href="'mailto:' + email ">{{email}}</a></p>
    </div>
</template>

<script>
export default {
    name: "FooterContent",
    data(){
        return {
            title: "Bob Safranski",
            telephone: "773-502-1244",
            email: "bob@atproperties.com",
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    .logo{
        width: 100%;
        max-width: 265px;
    }
    * {
        color: white;
        margin: 0.75rem 0;
    }
    hr{
        margin: 1rem 0;
        width: 70px;;
    }
    .contact{
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 3px;
    }
    .property-tile{
        display:block;
    }
}
@media (max-width: $mobile) {
    .footer-content{
        a{
            padding: 1rem 0;
        }
        .logo{
            width: 100%;
            max-width: 250px;
        }
    }
}
</style>