<template>
    <div id="footer">
        <FooterContent />
        <!-- <FooterSocialMedia /> -->
        <hr />
        <div class="company_logo">
            <img src="/images/company-logo.svg" alt="Company Logo">
        </div>
        <FooterMenu />
        <FooterCredits />
        <div class="img-container">
            <img src="/images/footer-housing.png" alt="Footer Housing">
        </div>
    </div>  
</template>

<script>
import FooterContent from "./Footer/FooterContent"
import FooterMenu from "./Footer/FooterMenu"
// import FooterSocialMedia from "./Footer/FooterSocialMedia"
import FooterCredits from "./Footer/FooterCredits"

export default {
    name: "Footer",
    components: {
        FooterContent,
        FooterMenu,
        // FooterSocialMedia,
        FooterCredits
    },
}
</script>

<style lang="scss" scoped>
#footer{
    //background: linear-gradient(90deg, rgba(16,16,16,0.9) 100%, rgba(16, 16, 16, 0.9) 100%), url("/images/bg-footer.jpg") no-repeat center center fixed;
    z-index: 1;
    hr {
        text-align: center;
        width: 80%;
        height: 1px;
        border: 0; 
        border-bottom: 1px solid #fff;
        margin: 0 auto;
        opacity: 0.15;
    }
    padding: 8rem 0 0 0;
    background: #000;

    .company_logo{
        text-align: center;
        padding: 1.75rem 0;
        img{
            height: 40px;
            width: auto;
        }
    }
    .img-container{
        text-align: center;
        padding-bottom: 3.5rem;
    }
}
@media (max-width: $mobile) { 
    #footer{
        padding-top: 4rem;
        .company_logo{
            img{
                width: 300px;
            }
        }
        hr{
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
        }
    }
}
</style>